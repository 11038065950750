.modalBackground {
  background-color: rgba(0, 0, 0, 0.7);
}

.modalContainer {
  width: 400px;
  height: 350px;
  border-radius: 12px;
  padding: 25px;
}

.modalContainer .body {
  flex: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
